import React, { useMemo } from 'react';
import Link from "components/common/Link";

interface Props {
  closeModal: () => void;
  isMasterExport: boolean;
}

const ConfirmExport = ({ closeModal, isMasterExport = false }: Props) => {
  const contentSection = useMemo(() => {
    if (isMasterExport) {
      return (
        <>
          Your report for the requested period is <br/>being generated. Please check back on the <br/>
          <Link
            href="/reports"
            passHref
          >
            <a
              id='reports-page-link'
              className='font-semibold underline-offset-4 hover:underline'
            >
              Reports Page
            </a>
          </Link> in some time.
        </>
      );
    }

    return (
      <>Your report for the requested period should <br/>be generated in a couple of minutes.</>
    );
  }, [isMasterExport]);

  return (
    <section className={'w-[350px] flex flex-col gap-6'}>
      <p className='pt-3'>{contentSection}</p>
      <button
        id='close-export-order-modal-button'
        className={'w-fit px-[20px] py-[10px] border border-theme_1_black rounded-[2px] text-theme_1_white bg-theme_1_black text-[14px] font-bold'}
        onClick={closeModal}
      >
        Ok, Got It
      </button>
    </section>
  );
};

export default ConfirmExport;
