import Tooltip from "components/Tooltip/_default/Tooltip";
import infoIcon from "assets/info.svg";
import Skeleton from "react-loading-skeleton";
import { isInvestorStore } from "utils/auth";

export interface CountCardProps {
  title: string | React.ReactNode;
  count: number | string | undefined;
  footerText?: string;
  footerLink?: string;
  footerLinkText?: string | number;
  toolTipText?: string | React.ReactNode;
  renderFooterText?: React.ReactElement;
  tooltipWrapperClass?: string;
  noBorder?: boolean;
}

const CountCard: React.FC<CountCardProps> = ({ title, count, footerText, footerLinkText, footerLink, toolTipText, renderFooterText, tooltipWrapperClass, noBorder }) => {
  return (
    <div className={`flex flex-col min-w-[150px] min-h-[120px] h-fit p-4 flex-1${noBorder ? '' : ' border border-[#E0E0E0] rounded-[10px]'}`}>
      <div className="flex items-center text-sm font-medium text-[#616161] mb-[2px]">
        <span className="mr-1">{title}</span>
        {toolTipText && (
          <Tooltip 
            message={toolTipText}
            img={infoIcon}
            textWrapperClass={tooltipWrapperClass}
            messageWrapperClass="!p-[10px]"
          />
        )}
      </div>
      <span className="text-2xl text-[#0A0A0A] font-bold">
        {count || count === 0 ? count : (
          <Skeleton 
            width={100} 
            height={22} 
          />
        )}
      </span>
      {footerText && (
        <div className="text-xs font-normal mt-2">
          <span className="text-[#A82525] mr-[6px]">{footerText}</span>
          {footerLinkText !== -1 ? (isInvestorStore() ? (
              <span>
                {footerLinkText}
              </span>
            ) : (
              <a
                className="text-[#0023DD] underline" 
                href={footerLink || "#"}
                target="_blank"
                rel="noreferrer"
              >
                {footerLinkText}
              </a>
            )
          ) : 'n/a'}
        </div>
      )}
      {renderFooterText && (
        <div className="text-xs font-normal mt-2">
          {renderFooterText}
        </div>
      )}
    </div>
  );
};

export default CountCard;
