import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from "chart.js";
import Tooltip from "components/Tooltip/_default/Tooltip";
import infoIcon from "assets/info.svg";
import { formatNumberInCommas } from "utils/misc";
import Skeleton from "components/LoadingSkeleton/Skeleton";

ChartJS.register(ArcElement, ChartTooltip, Legend);

export interface CountCardProps {
  title: string | undefined;
  count: number | string | undefined;
  toolTipText?: string | React.ReactNode;
  labels: string[]; 
  data: number[];
  backgroundColor: string[];
  toolTipComponent?: any;
  hoverCount?: number[];
}

const DoughnutCard: React.FC<CountCardProps> = ({ title, count, hoverCount, toolTipText, toolTipComponent, labels=[], data=[], backgroundColor=[] }) => {
  const formatChartLegends = () => {
    const legends: { label: string; value: number; color: string }[][] = [[]];

    labels.forEach((label, index) => {
      const prop = { label, value: data[index], color: backgroundColor[index] }

      if (legends[legends.length - 1].length > 4) return legends.push([prop]);

      legends[legends.length - 1].push(prop);
    })

    return legends;
  }
  
  return (
    <div className="flex flex-col border border-[#E0E0E0] rounded-[10px] pt-[14px] pb-[30px] px-4 flex-1">
      <div className="flex items-center text-sm font-medium text-[#616161] mb-[6px]">
        <span className="mr-1">{title || "--"}</span>
        {!toolTipComponent && toolTipText && (
          <Tooltip 
            message={toolTipText}
            img={infoIcon} 
          />
        )}
        {toolTipComponent && (React.cloneElement(toolTipComponent))}
      </div>
      <span className="text-2xl text-[#0A0A0A] font-bold mb-[14px]">{count || count === 0 ? formatNumberInCommas(count) : (
        <Skeleton
          width={80}
          height={22}
        />
      )}</span>
      <div className="flex gap-[40px]">
        {/* Chart */}
        <div className="">
          <Doughnut
            width={140}
            height={140}
            data={{
              labels: labels.map((label, index) => `${data[index]}% ${label}`),
              datasets: [{
                data,
                backgroundColor,
                hoverBackgroundColor: backgroundColor,
                // hoverBorderColor: (ctx: any) => ctx?.element?.options?.backgroundColor,
                hoverBorderColor: '#ffffff',
                borderWidth: 1,
              }],
            }}
            options={{
              spacing: 0,
              responsive: false,
              maintainAspectRatio: false,
              cutout: '55%',
              plugins: {
                legend: {
                  display: false,
                  position: 'right',
                  maxHeight: 500,
                  align: 'center',
                  labels: {
                    boxHeight: 8,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    color: '#757575',
                    padding: 8,
                    font: {
                      size: 13,
                    },
                  },
                },
                tooltip: {
                  usePointStyle: true,
                  backgroundColor: "#424242",
                  padding: 8,
                  callbacks: {
                    labelPointStyle: () => ({ pointStyle: 'rectRounded', rotation: 0 }),
                    label: (context) => ` ${context.label.split(" ").splice(1).join(" ")}`,
                    labelTextColor: () => '#9E9E9E',
                    labelColor: (context) => {
                      // @ts-ignore
                      const colorIndex = context.dataset.data.indexOf(context.raw);
                      // @ts-ignore
                      const color = context.dataset.backgroundColor[colorIndex];
                      
                      return {
                        borderColor: color,
                        backgroundColor: color,
                        borderDash: [0, 0],
                        borderRadius: 2,
                      };
                    },
                    beforeFooter: ([context]) => `${hoverCount ? formatNumberInCommas(hoverCount[context.dataIndex]) : '--'} (${context.raw}%)`,
                  },
                  footerFont: {
                    weight: '600',
                    size: 13,
                  },
                  bodyFont: {
                    size: 12,
                    weight: '500',
                  }
                }
              }
            }}
          />
        </div>
        {/* Legends */}
        <div className="flex gap-[20px] self-center w-[max-content] xsScreen:flex-col xsScreen:gap-2">
            {formatChartLegends().map((legends, index) => (
              <div 
                key={index} 
                className="flex flex-col gap-[8px]"
              >
                {legends.map(legend => (
                  <div 
                    key={legend.label} 
                    className="flex gap-[8px]"
                  >
                    <div 
                      className={`w-[8px] h-[8px] rounded-[2px]`} 
                      style={{ backgroundColor: legend.color }}
                    />
                    <div className="text-[9px] leading-3 text-[#757575] mt-[-2px]"><b className="text-[rgba(0,0,0,0.7)] w-[fit-content]">{legend.value}%</b> {legend.label}</div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DoughnutCard;
