import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import Image from "next/image";
import checkIcon from "assets/check.svg";

export type OptionType = { label: string; value: string };

export interface DropdownButtonProps {
  label?: string;
  options: OptionType[];
  onChange?: (selectedValue: OptionType) => void;
  defaultOption?: OptionType;
}

const dropdownRenderer = ({ props }: { props: any, methods: any }) => {
  const { options, onChange, values }: { options: OptionType[], values: OptionType[]; onChange: (option: OptionType[]) => void, dropdownMinWidth: string } = props;

  return (
    <div className={`bg-[#ffffff] !rounded !border-[none] min-w-[160px]`}>
      <ul className="flex flex-col py-[4px] text-xs text-[#424242] font-normal">
        {options.map(option => (
          <li 
            key={option.value}
            className={`flex items-center justify-between cursor-pointer py-[6px] px-4 hover:bg-[#F5F5F5] ${values[0].value === option.value ? 'text-[#0A0A0A]' : ""}`}
            onClick={() => onChange([option])}
          >
            <span>{option.label}</span>
            <span className="ml-[14px] w-[12px] h-[9px]">
              {values[0].value === option.value && <Image src={checkIcon} />}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ label, options, defaultOption, onChange }) => {
  const [selectedOption, setSelectedOption] = useState<OptionType>((defaultOption && defaultOption) || (options.length ? options[0] : { label: '', value: '' }));

  useEffect(() => {
    defaultOption && setSelectedOption(defaultOption);
  }, [defaultOption]);

  const contentRenderer = () => {
    return <>{selectedOption.label}</>;
  };

  const handleOnChange = ([selectedValue]: OptionType[]) => {
    setSelectedOption(selectedValue);
    onChange && onChange(selectedValue);
  }

  return (
    <div>
      <label className="text-xs">{label}</label>
      <Select 
        className='flex gap-2 items-center mt-1 !outline-none !shadow-none !select-none !px-2 !py-0 !bg-theme_1_grey3 !border !border-theme_1_border !rounded !text-[#424242] !text-xs !font-semibold min-w-[151px]'
        values={[selectedOption]}
        dropdownGap={2.5}
        options={options}
        contentRenderer={contentRenderer}
        dropdownRenderer={dropdownRenderer}
        onChange={handleOnChange} 
      />
    </div>
  );
};

export default DropdownButton;
