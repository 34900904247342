import Image from "next/image";
import exportIcon from "assets/export.svg";
import { exportOrdersReport } from "handlers/orders";
import { generateDateFilterQuery } from "utils/datePicker";
import Modal from "components/Modal/_default/Modal";
import ConfirmExport from 'components/Modal/ConfirmExport/ConfirmExport';
import { useState } from "react";
import TooltipForNoAccess from "../../TooltipForNoAccess";
import isAuthorized, {PERMISSIONS} from "../../../utils/isAuthorized";
import { CountryCode } from "constants/store";
export interface ExportMasterDataProps {
  dateFilter: any;
  storesPrimaryKey: any;
  countryCode?: CountryCode
}

const ExportMasterData:React.FC<ExportMasterDataProps> = ({
  dateFilter,
  storesPrimaryKey,
  countryCode,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertPopupOpen, setAlertPopup] = useState(false);

  const handleExportOrders = () => {
    if (storesPrimaryKey === '') return setAlertPopup(true);

    const searchParams = {
      storesPrimaryKey,
      countryCode,
      isMasterDataDownload: true,
      ...generateDateFilterQuery(dateFilter),
    };

    exportOrdersReport(
      searchParams,
      '',
      () => {
        setIsModalOpen(true);
      },
    );
  };

  return (
    <>
      <TooltipForNoAccess
        hasAccess={isAuthorized({permissionToCheck: PERMISSIONS.CRITICAL_ACCESS_EXPORT_DATA})}
        position="top"
        message="You don’t have access to export data.">
        <button
          id='export-order-button'
          className='border border-theme_1_border bg-theme_1_grey3 rounded px-2 py-[9px] flex gap-[7.5px] items-center'
          onClick={handleExportOrders}
          disabled={!isAuthorized({permissionToCheck: PERMISSIONS.CRITICAL_ACCESS_EXPORT_DATA})}
        >
          <Image
            src={exportIcon}
            alt='export-logo'
          />
          <span className='text-[12px] leading-[14px] font-semibold'>Export Master Data</span>
        </button>

      </TooltipForNoAccess>
      

      <Modal
          isVisible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ConfirmExport
            isMasterExport
            closeModal={() => setIsModalOpen(false)}
          />
      </Modal>
      <Modal
          isVisible={isAlertPopupOpen}
          onClose={() => setAlertPopup(false)}
        >
          <section className={'w-[350px] flex flex-col gap-6'}>
            <p>Please select a specific store from the store filter to export master data.</p>
            <button
              id='close-export-order-modal-button'
              className={'w-fit px-[20px] py-[10px] border border-theme_1_black rounded-[2px] text-theme_1_white bg-theme_1_black text-[14px] font-bold'}
              onClick={() => setAlertPopup(false)}
            >
              Ok, Got It
            </button>
          </section>
      </Modal>
    </>
  );
};

export default ExportMasterData;
