import React from "react";
import { Chart } from "react-chartjs-2";
import Tooltip from "components/Tooltip/_default/Tooltip";
import infoIcon from "assets/info.svg";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip as ChartJsTooltip,
  LineController,
  BarController,
  Filler,
} from 'chart.js';
import { numberKFormatter, getRupeeFormatted, formatNumberInCommas } from "utils/misc";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  ChartJsTooltip,
  LineController,
  BarController,
  Filler,
);

export interface MixedBarCharProps {
  title: string | undefined;
  toolTipText?: string | React.ReactNode;
  toolTipComponent?: any;
  ordersLegendCount: number;
  gmvLegendCount: number;
  data: {
    duration: string[];
    gmv: number[];
    orderCount: number[];
    maxOrderScale: number;
    maxGmvScale: number;
  }
}

const MixedBarChar: React.FC<MixedBarCharProps> = ({ title, toolTipComponent, toolTipText, data, ordersLegendCount, gmvLegendCount }) => {
  const gradientLineChartColorTop = (context: any) => {
    const ctx = context.chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 1200 * 0.14);
    gradient.addColorStop(0, 'rgba(255, 172, 0, 0.25)');
    gradient.addColorStop(1, 'rgba(255, 172, 0, 0)');    
    return gradient;
  };

  const gradientLineChartColorBtm = (context: any) => {
    const ctx = context.chart.ctx;
    const gradient = ctx.createLinearGradient(0, 1200 * 0.8, 0, 1200);
    gradient.addColorStop(0, 'rgba(255, 172, 0, 0)');
    gradient.addColorStop(1, 'rgba(255, 172, 0, 0.2)');   
    return gradient;
  };

  return (
    <div
      className="flex flex-col min-w-[160px] border border-[#E0E0E0] rounded-[10px] p-4 flex-1"
    >
      {/* Header */}
      <div className="flex items-start text-sm font-medium text-[#616161] mb-6 mt-0.5">
        <span className="mr-1 text-xl text-[#424242]">{title || "--"}</span>
        {!toolTipComponent && toolTipText && (
          <Tooltip 
            message={toolTipText}
            img={infoIcon} 
          />
        )}
        {toolTipComponent && (React.cloneElement(toolTipComponent))}

        {/* Chart legend */}
        <div className="ml-auto flex flex-col gap-[8px] mr-5">
          {/* Legend-1 */}
          <div className="flex gap-[5px]">
            <div className="w-[8px] h-[8px] bg-[#FFAC00] rounded-[2px]"></div>
            <div className="flex flex-col mt-[-2px]">
              <span className="text-[#757575] text-[9px] leading-3">Orders</span>
              <b className="text-[#0A0A0A] text-xs">{formatNumberInCommas(ordersLegendCount)}</b>
            </div>
          </div>
          {/* Legend-2 */}
          <div className="flex gap-[5px]">
            <div className="w-[8px] h-[8px] bg-[#8633FF] rounded-[2px]"></div>
            <div className="flex flex-col mt-[-2px]">
              <span className="text-[#757575] text-[9px] leading-3">GMV</span>
              <b className="text-[#0A0A0A] text-xs">{getRupeeFormatted(gmvLegendCount)}</b>
            </div>
          </div>
        </div>
      </div>

      {/* Chart */}
      <Chart 
        type="bar" 
        data={{
          labels: data?.duration,
          datasets: [
            {
              type: 'bar',
              order: 2,
              label: 'Orders',
              data: data?.orderCount,
              yAxisID: 'orders_y_axis',
              borderWidth: 0,
              // borderRadius: 3,
              borderSkipped: false,
              maxBarThickness: 30,
              // hoverBackgroundColor: 'rgba(94, 71, 210, 0.9)',
              backgroundColor: '#8633FF',
            },
            {
              type: 'line',
              order: 1,
              label: 'GMV',
              backgroundColor: 'white',
              data: data?.gmv,
              borderColor: '#FFAC00',
              fill: {
                target: 'origin',
                // @ts-ignore
                above: gradientLineChartColorTop, 
                // @ts-ignore
                below:  gradientLineChartColorBtm,
              },
              yAxisID: 'gmv_y_axis',
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 2,
              pointHitRadius: 10,
            },
          ],
        }}
        options={{
          scales: {
            x: {
              grid: {
                display: false,
                offset: true,
              },
              ticks: {
                color: '#757575',
                font: {
                  size: 9
                }
              }
            },
            orders_y_axis: {
              beginAtZero: true,
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                display: true,
                drawBorder: false,
                color: '#F5F5F5',
              },
              title: {
                display: true,
                text: 'Orders',
                color: '#757575',
                font: {
                  size: 9
                }
              },
              ticks: {
                maxTicksLimit: 5,
                color: '#757575',
                font: {
                  size: 9
                },
                precision: 0
              }
            },
            gmv_y_axis: {
              beginAtZero: true,
              type: 'linear',
              display: true,
              position: 'right',
              grid: {
                display: false,
                drawBorder: false
              },
              title: {
                display: true,
                text: 'GMV (₹)',
                color: '#757575',
                font: {
                  size: 9
                }
              },
              ticks: {
                maxTicksLimit: 5,
                color: '#757575',
                font: {
                  size: 9
                },
                precision: 0,
                callback: (label) => numberKFormatter(label),
              }
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              usePointStyle: true,
              backgroundColor: "#424242",
              padding: 8,
              titleMarginBottom: 12,
              titleFont: {
                weight: '500',
                size: 11,
              },
              titleColor: '#9E9E9E',
              bodyFont: {
                size: 12
              },
              bodySpacing: 8,
              callbacks: {
                labelPointStyle: () => ({ pointStyle: 'rectRounded', rotation: 0 }),
                title: ([{ label }]) => label,
                label: (context) => {
                  const value = context.dataset.type === 'line' ? `₹${context.formattedValue}` : context.formattedValue;
                  return ` ${context.dataset.label}:  ${value}` || '--';
                },
                labelColor: (context) => {
                  const color = context.dataset.type === 'bar' ? '#8633FF' : '#FFAC00';
                  return {
                    borderColor: color,
                    backgroundColor: color,
                    borderDash: [0, 0],
                    borderRadius: 2,
                  };
                },
              }
            }
          },
          elements: {
            bar: {
              borderRadius: {
                topRight: 3,
                topLeft: 3,
              }
            }
          }
        }}
      />
    </div>
  );
};

export default MixedBarChar;