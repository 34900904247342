import { memo, ReactNode } from "react";

export interface PageContainerProps {
  children?: ReactNode;
  className?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({ children, className= '' }) => {
  return (
    <article 
      className={`page-content-wrapper flex flex-col w-full min-h-screen font-[Inter] text-theme_1_grey1 pb-[60px] px-5 ${className} smScreen:px-4 smScreen:pb-4`}
    >
      {children}
    </article>
  );
};

export default memo(PageContainer);
