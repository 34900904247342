import React from "react";
import { Chart } from "react-chartjs-2";
import Tooltip from "components/Tooltip/_default/Tooltip";
import infoIcon from "assets/info.svg";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip as ChartJsTooltip,
  LineController,
  BarController,
  Filler,
} from 'chart.js';
import { formatNumberInCommas } from "utils/misc";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  ChartJsTooltip,
  LineController,
  BarController,
  Filler,
);

export interface BarChartProps {
  title: string | undefined;
  subtitle?: string | undefined;
  toolTipText?: string | React.ReactNode;
  toolTipComponent?: any;
  totalDataLabel: string;
  totalDataCount: number;
  yAxisLabel: string;
  data: {
    xAxisData: string[];
    yAxisData: number[];
  }
}

const BarChart: React.FC<BarChartProps> = ({ title, subtitle, totalDataLabel, totalDataCount, toolTipComponent, toolTipText, yAxisLabel, data }) => {

  return (
    <div
      className="flex flex-col min-w-[160px] border border-[#E0E0E0] rounded-[10px] p-4 flex-1"
    >
      {/* Header */}
      <div className="flex items-center text-sm font-medium text-[#616161] mb-6 mt-0.5">
        <div>
          <div className="text-zinc-600 text-lg font-medium leading-7">{title || "--"}</div>
          {subtitle && <div className='text-zinc-600 text-xs font-normal'>{subtitle}</div>}
        </div>
        {!toolTipComponent && toolTipText && (
          <Tooltip 
            message={toolTipText}
            img={infoIcon} 
          />
        )}
        {toolTipComponent && (React.cloneElement(toolTipComponent))}

        {/* Chart legend */}
        <div className="ml-auto flex flex-col gap-[8px] mr-5">
          {/* Legend-1 */}
          <div className="flex gap-[5px]">
            <div className="w-[8px] h-[8px] bg-[#8633FF] rounded-[2px]"></div>
            <div className="flex flex-col mt-[-2px]">
              <span className="text-[#757575] text-[9px] leading-3">{totalDataLabel}</span>
              <b className="text-[#0A0A0A] text-xs">{formatNumberInCommas(totalDataCount)}</b>
            </div>
          </div>
        </div>
      </div>

      {/* Chart */}
      <Chart 
        type="bar" 
        data={{
          labels: data?.xAxisData,
          datasets: [
            {
              type: 'bar',
              label: yAxisLabel,
              data: data?.yAxisData,
              yAxisID: 'data_y_axis',
              borderWidth: 0,
              // borderRadius: 3,
              borderSkipped: false,
              maxBarThickness: 30,
              // hoverBackgroundColor: 'rgba(94, 71, 210, 0.9)',
              backgroundColor: '#8633FF',
            }
          ],
        }}
        options={{
          scales: {
            x: {
              grid: {
                display: false,
                offset: true,
              },
              ticks: {
                color: '#757575',
                font: {
                  size: 9
                }
              }
            },
            data_y_axis: {
              beginAtZero: true,
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                display: true,
                drawBorder: false,
                color: '#F5F5F5',
              },
              title: {
                display: true,
                text: yAxisLabel,
                color: '#757575',
                font: {
                  size: 9
                }
              },
              ticks: {
                maxTicksLimit: 10,
                color: '#757575',
                font: {
                  size: 9
                },
                precision: 0
              }
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              usePointStyle: true,
              backgroundColor: "#424242",
              padding: 8,
              titleMarginBottom: 12,
              titleFont: {
                weight: '500',
                size: 11,
              },
              titleColor: '#9E9E9E',
              bodyFont: {
                size: 12
              },
              bodySpacing: 8,
              callbacks: {
                labelPointStyle: () => ({ pointStyle: 'rectRounded', rotation: 0 }),
                title: ([{ label }]) => label,
                label: (context) => {
                  const value = context.formattedValue;
                  return ` ${context.dataset.label}:  ${value}` || '--';
                },
                labelColor: (context) => {
                  const color = context.dataset.type === 'bar' ? '#8633FF' : '#FFAC00';
                  return {
                    borderColor: color,
                    backgroundColor: color,
                    borderDash: [0, 0],
                    borderRadius: 2,
                  };
                },
              }
            }
          },
          elements: {
            bar: {
              borderRadius: {
                topRight: 3,
                topLeft: 3,
              }
            }
          }
        }}
      />
    </div>
  );
};

export default BarChart;