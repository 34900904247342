import { default as  ReactSkeleton } from 'react-loading-skeleton';
import { SkeletonProps } from 'react-loading-skeleton'

const Skeleton: React.FC<SkeletonProps> = (props) => {
  return (
    <ReactSkeleton
      baseColor="#f3f3f3"
      highlightColor='#ecebeb'
      {...props}
    />
  );
};

export default Skeleton;
