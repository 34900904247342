import { isSameDay, startOfDay, endOfDay, getUnixTime, format } from 'date-fns';

function isSelected (range: any) {
  // @ts-ignore
  const definedRange = this?.range?.();

  return (
    isSameDay(range?.startDate, definedRange?.startDate)
    && isSameDay(range?.endDate, definedRange?.endDate)
  );
}

export function createStaticRanges (ranges: any[]) {
  return ranges.map((range) => ({ ...range, isSelected }));
}

export const checkIfSameDateRange = (range1: any, range2: any) => {
  return (
    isSameDay(range1?.startDate, range2?.startDate) && isSameDay(range1?.endDate, range2?.endDate)
  )
};

export const generateDateFilterQuery = (dateFilter: any = {}) => {
  const { startDate, endDate } = dateFilter || {};

  const dateFilterQuery = {
    ...((startDate && endDate) && ({
      startDate: getUnixTime(startOfDay(startDate)),
      endDate: getUnixTime(endOfDay(endDate)),
    }))
  };

  return dateFilterQuery;
};

export const generateDateFilterInStringQuery = (dateFilter: any = {}) => {
  const { startDate, endDate } = dateFilter || {};

  const dateFilterQuery = {
    ...((startDate && endDate) && ({
      startDate: format(startOfDay(startDate), 'yyyy-MM-dd'),
      endDate: format(endOfDay(endDate), 'yyyy-MM-dd'),
    }))
  };

  return dateFilterQuery;
};
